body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dx-row td {
  vertical-align: middle !important;
}

.dx-row:hover {
  background-color: rgba(0, 0, 0, 0.075);
  transition: background-color 0.3s ease-in-out;
}

.dx-htmleditor,
.ql-container {
  height: 100%;
}
